// App Global Sass
// --------------------------------------------------
// Put style rules here that you want to apply globally. These
// styles are for the entire app and not just one component.
// Additionally, this file can be also used as an entry point
// to import other Sass files to be included in the output CSS.


@import "theme/font.scss";

router-outlet + * {
  display: flex;
  flex-direction: row;
  flex: 1 1 auto;
  justify-content: center;  
}

html, body { 
  height: 100%; 
}

body { 
  margin: 0; 
  font-family: Roboto, "Helvetica Neue", sans-serif; 
}
