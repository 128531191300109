// Be sure that you only ever include this mixin once!
@use '@angular/material' as mat;
@use 'comydo-theme';
//@use '@angular/material/theming';
//@use 'sass:map';


@include mat.core();

$comydo-primary: mat.get-theme-color(comydo-theme.$light-theme, primary, 60);
$comydo-secondary: mat.get-theme-color(comydo-theme.$light-theme, secondary, 60);
$outline-color: mat.get-theme-color(comydo-theme.$light-theme, secondary, 70);

$on-primary: mat.get-theme-color(comydo-theme.$light-theme, on-primary);
$on-secondary: mat.get-theme-color(comydo-theme.$light-theme, on-secondary);

html {
  //TODO: It would be more efficient to import only the used components, like:
  //@include mat.core-theme(comydo-theme.$light-theme);
  //@include mat.button-theme(comydo-theme.$light-theme);
  @include mat.all-component-themes(comydo-theme.$light-theme);
  //TODO: switch to the M3 way, instead of using e.g. color="warn"
  @include mat.color-variants-backwards-compatibility(comydo-theme.$light-theme);

  // Apply the light theme only when the user prefers light themes.
  // @media (prefers-color-scheme: light) {
  //   // Use the `-color` mixins to only apply color styles without reapplying the same
  //   // typography and density styles.
  //   @include mat.core-color($light-theme);
  //   @include mat.all-component-themes($light-theme);
  // }
}


$center-layout-max-width: 100%;
    
@mixin center-layout($width: $center-layout-max-width) {
  display: flex;
  flex-direction: row;
  justify-content: center;
  
  >* {
    max-width: $width;
    width: $width;
  }
}
